var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "create-box" },
    [
      _c("i-header", {
        attrs: { title: _vm.isEdit ? "编辑会议" : "创建会议" },
        on: { "back-event": _vm.toBack },
      }),
      _vm.template
        ? _c(
            "div",
            { staticClass: "cnt-box" },
            [
              _c("i-tips", { attrs: { tipsList: _vm.tipsList } }),
              _c(
                "i-form",
                {
                  ref: "form",
                  attrs: { "scroll-to-error": "" },
                  on: { submit: _vm.onSubmit },
                },
                [
                  _vm._l(_vm.template[0], function (item) {
                    return _c("iFormItem", {
                      key: item.type + item.controlName,
                      ref: item.controlName,
                      refInFor: true,
                      attrs: {
                        formData: _vm.formData,
                        formTemplate: _vm.template,
                        "model-val": _vm.formData[item.controlName],
                        "form-item": item,
                      },
                      on: { callBack: _vm.formCallBack },
                    })
                  }),
                  _vm.template[1]
                    ? _c(
                        "div",
                        { staticClass: "more-box" },
                        [
                          !_vm.showMore
                            ? _c(
                                "div",
                                {
                                  staticClass: "more",
                                  on: {
                                    click: function ($event) {
                                      _vm.showMore = !_vm.showMore
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v("更多"),
                                  ]),
                                  _c(
                                    "div",
                                    [
                                      _c("i-icon", {
                                        attrs: {
                                          color: "#90939a",
                                          name: "icon-arrow-down-bold",
                                          size: 12,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.showMore
                            ? [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v(_vm._s(_vm.template[1][0].title)),
                                ]),
                                _vm._l(_vm.template[1], function (item, index) {
                                  return _c("iFormItem", {
                                    key: index,
                                    attrs: {
                                      "model-val":
                                        _vm.formData[item.controlName],
                                      "form-item": item,
                                    },
                                    on: { callBack: _vm.formCallBack },
                                  })
                                }),
                              ]
                            : _vm._e(),
                          _vm.showMore
                            ? _c(
                                "div",
                                {
                                  staticClass: "more",
                                  on: {
                                    click: function ($event) {
                                      _vm.showMore = !_vm.showMore
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v("收起"),
                                  ]),
                                  _c(
                                    "div",
                                    [
                                      _c("i-icon", {
                                        attrs: {
                                          color: "#90939a",
                                          name: "icon-arrow-up-bold",
                                          size: 12,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  !_vm.isGroupMeeting && !_vm.isTMIS
                    ? _c(
                        "i-button",
                        {
                          attrs: {
                            loading: _vm.loading,
                            disabled: _vm.loading,
                            "loading-text": "提交",
                            block: "",
                            round: "",
                            type: "primary",
                            size: "normal",
                          },
                        },
                        [_vm._v(" 提交 ")]
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm.isGroupMeeting
                ? _c(
                    "div",
                    { class: _vm.btnClass },
                    _vm._l(_vm.pfizerBtns, function (item, index) {
                      return _c(
                        "i-button",
                        {
                          key: index,
                          attrs: { type: item.type },
                          on: { click: item.callback },
                        },
                        [_vm._v(_vm._s(item.text))]
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _vm.isTMIS
                ? _c(
                    "div",
                    { staticClass: "btns-1" },
                    [
                      _c(
                        "i-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.pfizerSaveForTMIS },
                        },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c("i-skeleton", { attrs: { title: "", row: 8 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }