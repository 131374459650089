/**
 * @description 获取两个时间之间的时间差，以供后续的比较时间大小
 * @author Lt.Ethan Cho
 */

export default function timeDiff (time1){
        return function (time2) {
                const handler = (params) => {
                        return params.replace(/:/g, '').length === 6 ? parseInt(params.replace(/:/g, '')) / 100 : params.replace(/:/g, '');
                }
                return handler(time1) - handler(time2);
        }
}
