<template>
    <div class="create-box">
        <i-header :title="isEdit ? '编辑会议' : '创建会议'" @back-event="toBack"></i-header>
        <div v-if="template" class="cnt-box">
            <i-tips :tipsList="tipsList"></i-tips>
            <i-form ref="form" @submit="onSubmit" scroll-to-error>
                <iFormItem v-for="item in template[0]" :key="item.type + item.controlName" :formData="formData"
                           :formTemplate="template" :model-val="formData[item.controlName]" :ref="item.controlName"
                           :form-item="item" @callBack="formCallBack"></iFormItem>
                <div class="more-box" v-if="template[1]">
                    <div class="more" v-if="!showMore" @click="showMore = !showMore">
                        <div class="name">更多</div>
                        <div>
                            <i-icon color="#90939a" name="icon-arrow-down-bold" :size="12"/>
                        </div>
                    </div>
                    <template v-if="showMore">
                        <div class="title">{{ template[1][0].title }}</div>
                        <iFormItem v-for="(item, index) in template[1]" :key="index"
                                   :model-val="formData[item.controlName]" :form-item="item"
                                   @callBack="formCallBack"></iFormItem>
                    </template>
                    <div class="more" v-if="showMore" @click="showMore = !showMore">
                        <div class="name">收起</div>
                        <div>
                            <i-icon color="#90939a" name="icon-arrow-up-bold" :size="12"/>
                        </div>
                    </div>
                </div>
                <i-button v-if="!isGroupMeeting && !isTMIS" :loading="loading" :disabled="loading" loading-text="提交" block round type="primary"
                          size="normal"> 提交
                </i-button>
            </i-form>
            <div :class="btnClass" v-if="isGroupMeeting">
                <i-button v-for="(item, index) of pfizerBtns" @click="item.callback" :key="index" :type="item.type">{{item.text}}</i-button>
            </div>
            <div class="btns-1" v-if="isTMIS">
                <i-button  @click="pfizerSaveForTMIS" type="primary">保存</i-button>
            </div>
        </div>
        <i-skeleton v-else title :row="8"/>
    </div>
</template>

<script>
import {Dialog} from 'vant'
import iFormItem from "@/components/iFormItem/iFormItem";
import generic from "../utils/generic";
import {getCookie} from "tiny-cookie";
import timeDiff from "@/utils/filter/timeDifference";
import {SmartStorage} from "@/utils/smart-core-util";
import _cloneDeep from "lodash/cloneDeep";

export default {
    name: "CreateEvent",
    components: {
        iFormItem,
    },
    data() {
        return {
            Dialog,
            showMore: false,
            directoryId: "",
            template: undefined,
            checkList: null,
            formData: {},
            isEdit: false,
            miceInfo: {},
            fieldNames: {},
            tipsList: [],
            loading: false,
            tenantId: this.$cookie.get("tenant_id") || this.$smartStorage.get("tenant_id"),

            config: {},
            infoNum: '', // 给辉瑞拼团使用的编号
            buttonsForPfizer: '', // 辉瑞拼团-按操作按钮
            isPfizerEdit: false, // 辉瑞拼团-是否编辑
            isPfizerDraft: false, // 辉瑞拼团-是否草稿
            isGroupMeeting: getCookie('tenant') === 'pfizer' && this.$smartStorage.get('selectedEventType') === 'groupMeeting', // 辉瑞拼团
            isTMIS: getCookie('tenant') === 'pfizer' && this.$smartStorage.get('selectedEventType') === 'TMIS', // 辉瑞老yes
            pfizerBtnsBase: [
                {
                    key: 'delete',
                    callback: eval('() => {this.Dialog.confirm({title: \'提示\', message: \'是否删除当前会议？\'}).then(() => {this.deletePfizerGroup()})}'),
                    type: 'danger',
                    text: '删除'
                },
                {
                    key: 'draft',
                    callback: this.pfizerSaveDraft,
                    type: 'default',
                    text: '保存草稿'
                },
                {
                    key: 'create',
                    callback: this.pfizerPublish,
                    type: 'primary',
                    text: '发布'
                },
                {
                    key: 'edit',
                    callback: this.pfizerEditFunc,
                    type: 'primary',
                    text: '发布'
                }
            ],
            hospitalObj: {},
            NeedConfirm: true,
        };
    },
    created() {
        if (this.tenantId) {
            this.getConfig()
            this.getTips(this.tenantId);
        }
        if(this.isGroupMeeting){
            if(this.$route.query.isEdit){
                this.isPfizerEdit = true;
            }else {
                this.generateInfoNum();
            }
            if(this.$route.query.isDraft){
                this.isPfizerDraft = true;
            }
        }

    },
    mounted() {
        // this.$nextTick(() => {
        //     this.formData = {name: 'hhhh'}
        // })
        // console.log(this.formData);
    },
    methods: {
        getGroupType(){
            const types = new Map([
                [this.isGroupMeeting, 'CreateEventForGroupMeeting'],
                [this.isTMIS, 'CreateEventForTMIS']
            ]);
            if(this.isGroupMeeting){
                return types.get(this.isGroupMeeting);
            }else if(this.isTMIS){
                return types.get(this.isTMIS)
            }else {
                return "CreateEvent"
            }
        },
        getConfig() {
            generic
                .getConfigs(
                    {
                        miceId: this.$route.query.miceId,
                        group: this.getGroupType(),
                        router: this.$route.path,
                    },
                    {tenantId: this.tenantId}
                )
                .then((config) => {
                    this.config = config;
                    for (const configKey in config) {
                        if (config[configKey].callback) this[config[configKey].callback]();
                    }
                });
        },
        // 根据租户获取创建会议模板
        async queryFormTemplate(dId) {
            let roleCodeList = this.$cookie.get("role_codes").split(",");

            let profileData = JSON.parse(this.$smartStorage.get('userMsg')?.profileData || "{}");
            let _config = generic.replaceEvalObject(this.config['formTemplate'],
                Object.assign({roleCodeList: roleCodeList, directoryId: this.tenantId}, profileData));
            let res = await this.$service.GenericPost(_config);
            if (res && res.success && res.content) {
                res.content.template.map((element) => {
                    element.map((item) => {
                        if (item.controlName) {
                            this.$set(this.formData, item.controlName, "");
                        }
                        if (item.controlName == "eventOrg" && item.params) {
                            item.params.OrgId = this.tenantId;
                        }
                        if (item.type == "iCascaderRest") {
                            this.setFiledName(item);
                        }
                        return item;
                    });
                    return element;
                });
                this.stashTemplate = res.content.template;
                this.template = this.jsonCopy(this.stashTemplate);
                console.log(this.template);
                this.template[0] = [].concat(res.content.template[0]).filter(i => {
                    return !i.showExpr
                }); // 这个参数目前只在默沙东用，未配置的情况下暂时未发现对其他租户的影响
                this.checkList = res.content.checkList || [];

                console.log("====添加会议的表单template", this.stashTemplate);
                console.log("====表单验证规则", this.checkList);
                if (this.$route.query.type == "edit" || this.isPfizerEdit) {
                    this.showFormDate();
                }
                
                // this.$nextTick(() => {
                //     setTimeout(() => {
                //         if (this.$route.query.type == "edit" || this.isPfizerEdit) {
                //             this.showFormDate();
                //         }
                //     }, 400);
                // });
            }
        },
        // 返显表单数据
        showFormDate() {
            this.isEdit = true;
            this.miceInfo = this.$store.state.miceInfo;
            // console.log('回显的-=-=-=',this.miceInfo);
            for (let index = 1; index < this.stashTemplate[0].length; index++) {
                let templateItem = this.stashTemplate[0][index];
                let currentItem = this.miceInfo[templateItem.controlName] !== undefined ? this.miceInfo[templateItem.controlName] : this.miceInfo.ExtData[templateItem.controlName];
                // console.log("====需要反显的字段", templateItem.type, templateItem.label, templateItem.controlName, currentItem);
                // 需要考虑值为0的情况
                if (currentItem !== undefined) {
                    if (templateItem.type == "iSelectRest") {
                        this.formCallBack(currentItem, 0, templateItem);
                    } else if (templateItem.type == "iInput") {
                        this.formData[templateItem.controlName] = currentItem;
                    } else if (templateItem.type == "iCascaderRest") {
                        if (templateItem.controlName == "eventCity") {
                            this.$nextTick(() => {
                                this.$refs.eventCity[0]?.$children[0]?.reassign &&
                                this.$refs.eventCity[0].$children[0].reassign(currentItem, this.jsonCopy(currentItem).split("/").pop());
                                this.formCallBack(currentItem, 0, templateItem);
                            });
                        }
                    } else if (templateItem.type == "iDatePicker") {
                        this.$nextTick(() => {
                            const echoDate = this.miceInfo.ExtData[templateItem.controlName]||this.miceInfo[templateItem.controlName]; // 如果是时间time直接回显不需要经过new Date()
                            this.$refs[templateItem.controlName][0]?.$children[0]?.reassign &&
                            this.$refs[templateItem.controlName][0].$children[0].reassign(echoDate);
                        });
                    } else if (templateItem.type == "multipleSelect") {
                            // 存在相同controlName，但是类型不一
                        setTimeout(() => {
                            console.log('currentItem',currentItem);
                            this.$refs[templateItem.controlName][0]?.$children[0]?.reassign &&
                            this.$refs[templateItem.controlName][0].$children[0].reassign(currentItem);
                        }, 0)
                    } else {
                        this.formData[templateItem.controlName] = currentItem;
                    }
                }
            }
        },
        onSubmit(values) {
            // console.log('values',values);
            this.loading = true;
            // 过滤values，去掉无效信息和SourceData,字符转换数字
            for (const key in values) {
                if (key == "undefined" || key.includes("SourceData")) {
                    delete values[key];
                }
                if (this.template[0].find((x) => x.controlName == key) && this.template[0].find((x) => x.controlName == key).type == "number") {
                    values[key] = Number(values[key]);
                }
            }
            let context = values;
            let _this = this;
            // console.log("====表单数据汇总", context, _this);
            // 验证表单数据
            let isCheckAllPass = true;
            let checkExpress = this.checkList.filter((x) => {
                try {
                    return eval(x.expr); // 默沙东会在此执行后，通过设置isCheckAllPass=false终止后续的接口上传（ui-template的配置），而走submitForMSD方法
                } catch (error) {
                    console.log(error);
                }
            });
            // console.log("====命中表单验证规则", checkExpress);

            if (checkExpress.length) {
                checkExpress.map((express) => {
                    express.rules.map((rule) => {
                        try {
                            if (eval(rule.expr)) {
                                // 通过验证
                            } else {
                                this.$itoast.fail(rule.errorMsg);
                                isCheckAllPass = false;
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    });
                });
            }
            if (isCheckAllPass) {
                // 城市信息分开上传
                let eventCityArray = values.eventCity ? values.eventCity.split("/") : [];

                let params = {
                    Name: values.Name,
                    QtyAttender: values.QtyAttender || 0,
                    QtyAttenderExt: values.QtyAttenderExt || 0,
                    QtyAttenderInt: values.QtyAttenderInt || 0,
                    Country: "中国",
                    Province: eventCityArray[0],
                    City: eventCityArray[1] ? eventCityArray[1] : eventCityArray[0],
                    DtStart: values.DtStart,
                    DtEnd: values.DtEnd,
                    Remark: values.Remark || "",
                    UserId: this.$cookie.get("userId"),
                    DirectoryId: this.tenantId,
                    TypeDictVal: values.TypeDictVal,
                };

                // 添加配置的基础信息
                let childOfRoots = this.stashTemplate[0].filter((x) => x.childOfRoot).map((x) => x.controlName);
                childOfRoots.map((item) => {
                    params[item] = values[item];
                });

                // 基础信息之外的数据全部放入extData
                let extData = this.isEdit ? this.miceInfo.ExtData : {};
                for (const key in values) {
                    if (params[key] === undefined) {
                        extData[key] = values[key];
                    }
                }
                if (eventCityArray.length > 2) {
                    extData["region"] = eventCityArray[eventCityArray.length - 1];
                } else {
                    extData["region"] = "";
                }
                // 将开始日期和结束日期的时分存入extData
                extData["DtStart"] = values.DtStart;
                extData["DtEnd"] = values.DtEnd;

                // 讲配置指定的信息放入ExtData
                let childOfExtData = this.stashTemplate[0].filter((x) => x.childOfExtData).map((x) => x.controlName);
                childOfExtData.map((item) => {
                    extData[item] = values[item];
                });

                params.ExtData = JSON.stringify(extData);

                // 表单提交模式
                let actions = "createMice";
                if (this.isEdit) {
                    params["MiceId"] = this.miceInfo.MiceId;
                    params["OrderId"] = this.miceInfo.OrderId;
                    actions = "modifyMice";
                }
                this.$service[actions](params).then((res) => {
                    this.loading = false;
                    if (res.success) {
                        this.$itoast.success("操作成功!");
                        // this.$root.$eventHub.$emit("updateEventList", { lastReload: true });
                        this.$router.back();
                    }
                });
            } else {
                this.loading = false;
            }
        },
        // 预设服务品类
        SetCategories(miceId) {
            let params = {
                db: "smartx_tpm",
                collection: "cfg_crossevent_elements",
                filter: {
                    Category: "iAppCreateEventCategory",
                },
                projection: {
                    _id: 0,
                    Sort: 0,
                },
                sort: {
                    Sort: 1,
                },
                ContextDataKeys: {
                    MiceId: miceId,
                },
            };
            this.$service.SetCategories(params).then((res) => {
                if (res && res.success) {
                    this.$router.back();
                }
            });
        },
        setFiledName(item) {
            this.fieldNames[item.controlName] = {
                text: item.txtName,
                value: item.valName,
                children: item.children,
            };
        },
       
        // 默沙东提交接口
        beforeSubmitForMSD(context) {
                this.$service.checkMSDMiceLegality({extNum: context.prNum}).then(res => {
                    if (res.success && !res.content) {
                        this.submitToMSD(context);
                    } else {
                        this.$itoast.success(res.msg);
                    }
                })
        },
        submitToMSD(context){
            const {dtStart, prNum, subprNum} = context;
            const params = {
                "prNum": this.NeedConfirm ? prNum : subprNum || prNum,
                "dtStart": dtStart,
                "NeedConfirm": this.NeedConfirm
            }
    
            this.$service.submitMSDMice({...params}).then(resp => {
                console.log(resp);
                // debugger
                if (resp.success && resp.code === 201) {
                    const arr = this.stashTemplate[0].map(i => {
                        if(i.controlName === 'subprNum'){
                            i.options = resp.content.map(x => ({...x, showPrNum: x.city ? x.prNum + ' ' + x.city : x.prNum}));
                        }
                        return i
                    })
                    this.NeedConfirm = false;
                    this.$set(this.template,0,arr)
                }else if(resp.success === false && resp.code === 0) {
                    // this.$itoast.fail(resp.msg);
                    // this.$router.back();
                }else {
                    this.$itoast.success("操作成功!");
                    this.$router.back();
                }
            })
        },
        async getTips(did) {
            let params = {
                collection: "cfg-crossevent-noticebar",
                useCaching: false,
                cachingKey: "",
                filter: {
                    TenantCode: "crossevent",
                    DirectoryId: this.tenantId,
                    EntryCode: "ievent-create-meeting",
                },
                projection: {
                    _id: 0,
                },
            };
            let res = await this.$service.GetSettings(params);
            if (res && res.success && res.content) {
                this.tipsList = res.content.content;
            }
        },
        formCallBack(value, index, formItem) {
            // console.log(value);
            // 表单数据赋值
            if (value) {
                this.formData[formItem.controlName] = value.val || value.value || value[formItem.valName] || value;
                // 特殊赋值部分：
                // 1，辉瑞yes中，跨院云会议需要默认一个会议名称
                if(this.isTMIS && formItem.controlName === "TypeDictVal" && value.val === "跨院云会议"){
                    this.formData["Name"] = "LB–DM（责任人）-年/月/日–1-5/总会场数量-会议主题";
                }
            } else {
                this.formData[formItem.controlName] = "";
            }
            console.log('formItem',formItem);
            // 执行表单配置功能
            if (['iCascaderRest', 'iSelectRest'].includes(formItem.type)) {
                this.formVisible(value, index, formItem)
            } else if (formItem.type == "automaticFill") {
                // 赋值其他表单项
                // console.log("====需要自动填充内容", value, formItem.fillFormItem);
                this.formData[formItem.controlName] = value[formItem.controlName];
                formItem.fillFormItem.map((item) => {
                    if (['DtStart', 'DtEnd'].includes(item.fillControlName)) {
                        this.$nextTick(() => {
                            this.$refs[item.fillControlName][0].$children[0].reassign(new Date(value[item.fromValName]));
                        });
                    } else {
                        this.formData[item.fillControlName] = value[item.fromValName];
                    }
                });
                if (formItem.doVisibleRuleExpr) {
                    this.formVisible(value, index, formItem)
                }
            }
        },
        toBack() {
            this.$router.back();
        },
        formVisible(value, index, formItem) {
            // 根据表单项的值控制其他表单项的显隐藏
            let context = this.formData;
            const _this = this;
            console.log("====formitem的callback", formItem.controlName, value);
            // if(context.eventCity){_this.$service.queryDict({'keyword':'','keyCode':'CPC-City','lnkVal': context.eventCity.split('/')[0]}).then((res) => {if(res.success){const target = res.content.find(i => i.val === context.eventCity.split('/')[1]);const {lnkVal,val, itsExtData: {pingCityId,gps}} = target;const result = {"city": {val: pingCityId, txt: val}, "country": {val: "中国", txt: "中国"}, "gps": gps, "province": {val: lnkVal, "txt": lnkVal}};_this.miceInfo.ExtData = Object.assign({..._this.miceInfo.ExtData,...result});}})}
            this.template[0] = this.stashTemplate[0].filter((item) => {
                if (item.visibleRuleExpr) {
                    // 命中规则才显示
                    try {
                        return item.visibleRuleExpr.every((subItem) => eval(subItem));
                    } catch (error) {
                        console.log(error);
                    }
                } else {
                    return true;
                }
            });
        },
        // 辉瑞拼团需要获取编号
        generateInfoNum(){
            const params = {
                PaddingCount: 5,
                Prefix: `INFO${this.getStandardDate()}`
            }
            this.$service.getInfoNumForPfizer(params).then(res => {
                if(res.success){
                    this.infoNum = res.content;
                }
            })
        },
        // 获取年月日
        getStandardDate(){
            const date = new Date();
            const y = date.getFullYear();
            const m = date.getMonth() + 1;
            const d = date.getDate();
            return y + '' + m + '' + d + ''
        },
        // 获取辉瑞团购的按钮配置
        getConfigForPfizer(){
            const params = {
                "collection":"cfg-ievent-eventList-template",
                "filter":{
                    "tenantCode":"pfizerGroupButtons",
                    "tenantId": getCookie('tenant_id')
                }
            }
            this.$service.QuerySettings(params).then(res => {
                if(res.success){
                    this.buttonsForPfizer = res.content[0].template;
                }
            })
        },
        pfizerSaveDraft(){
            const obj = this.isPfizerEdit ? ['edit_draft', 'U'] : [ 'draft', 'C'];
            this.pfizerSaveCommonApi(obj[0], obj[1]);
        },
        beforePfizerPublish(){
            const params = {
                "db":"smartx_tpm",
                "collection":"cfg_rule_node",
                "filter":{
                    "NodeCode":{
                        "$in": ["EventGroup-PublishOrJoinGroup"]
                    }
                },
                "ContextDataKeys":{
                    "Version":3,
                    "InfoId": this.$route.query.type === "edit" ? this.miceInfo.infoId : this.infoNum
                }
            };
            return new Promise (resolve => {
                this.$service.ExecuteRuleNode(params).then(res => {
                    resolve(res);
                })
            })
        },
        async pfizerPublish(){
            const result = await this.beforePfizerPublish();
            if(result.success) {
                this.pfizerSaveCommonApi('create')
            }else {
                this.$itoast(result.msg);
            }
       
        },
        async pfizerEditFunc(){
           const result = await this.beforePfizerPublish();
            if(result.success) {
                this.pfizerSaveCommonApi('edit_info', 'U')
            }else {
                this.$itoast(result.msg);
            }
        },
        deletePfizerGroup(){
            // "schema":"smartx_meta",
            //     "object":"group_event_infos",
            //     "action":"U",
            //     "clientAction":"delete",
            //     "metas":[{"infoId":"3a107b28-3e7e-c858-aeb9-bfae439b954a","isDeleted":1}]}
            this.pfizerSaveCommonApi('delete', 'U')
        },
        // 辉瑞拼团保存接口
        pfizerSaveCommonApi(clientAction, action){
            // console.log('store---',this.$store.state.miceInfo);
            // console.log(this.$refs.form.getValues());
            this.$refs.form.validate().then(() => {
                const {
                    name,
                    eventCity,
                    DtStartDate,
                    DtEndd,
                    DtStart,
                    DtEnd,
                    DtEndDate,
                    DtEnddd,
                    DtEnddsd,
                    hospital,
                    hospitalType,
                    productrrFields,
                    officeAdd44ressd,
                    hospitalTyspe1,
                    officeAd3dressd,
                    officeAd2dressd,
                    proerduct,
                    producst,
                    // medicalTitle
                } = this.$refs.form.getValues();
                // 处理省市区
                // console.log(eventCity);
                const [province, city, district ] = eventCity.split('/');
                // 处理医院名称
                const hospitalArr = this.$refs.form.$children[6].$children[0].columns;
                const hospitalName = hospitalArr.length ? hospitalArr.find(x => x.code === hospital).name : '';
                // 处理科室名称
                const hospitalDepts = this.$refs.form.$children[8].$children[0].columns;
                const hosArr = productrrFields.split(',');
                const hospitalDepartmentTxt = hospitalDepts.filter(x => hosArr.includes(x.val)).map(i => i.txt).join(',');
                // 组成参数
                const params = {
                    "schema": "smartx_meta",
                    "object": "group_event_infos",
                    "action":  action || "C",
                    "clientAction": clientAction,
                    "metas": [{
                        "groupNum": this.isPfizerEdit ? this.$store.state.miceInfo.groupNum : this.infoNum,
                        "name": name,
                        "city": {
                            "CountyDictTxt": district,
                            "CountyDictVal": district,
                            "ProvinceDictTxt": province,
                            "ProvinceDictVal": province,
                            "CityDictTxt": city,
                            "CityDictVal": city
                        },
                        "eventDate": DtStartDate,
                        "expiryDate": DtEndd,
                        "eventTime": {
                            "startTime": DtStart,
                            "endTime": DtEnd,
                            "alternativeDate": DtEndDate,
                            "alternativeStartTime": DtEnddd,
                            "alternativeEndTime": DtEnddsd
                        },
                        "hospitalData": {
                            "hospitalName": hospitalName,
                            "hospitalCode": hospital,
                            "hospitalTypeTxt": hospitalType,
                            "hospitalTypeVal": hospitalType,
                            "hospitalDepartmentTxt": hospitalDepartmentTxt,
                            "hospitalDepartmentVal": productrrFields
                        },
                        "qtySpeaker": officeAdd44ressd ? officeAdd44ressd : 0,
                        "speekerData": {
                            "ContentTypeTxt": hospitalTyspe1,
                            "ContentTypeVal": hospitalTyspe1,
                            "SpeakerInfo": officeAd3dressd,
                            // "MedicalTitle": medicalTitle
                        },
                        "qtyAttend": officeAd2dressd,
                        "productData": {"ProductTxt": proerduct, "ProductVal": proerduct},
                        "therapeuticScopesData": {"TherapeuticScopesTxt": producst, "TherapeuticScopesVal": producst},
                        "extData": {"DtStartDate": DtStartDate},
                        "descr": "",
                        "status": clientAction !== 'delete' ? (clientAction === 'draft' ? 0 : 1 ): null,
                    }]
                }
                if(this.isPfizerEdit){
                    params.metas[0].infoId = this.$store.state.miceInfo.infoId;
                    if(clientAction === 'delete'){
                        params.metas[0].isDeleted = 1;
                    }
                }
                this.$service.GenericCommit(params).then(res => {
                    if(res.success){
                        if(clientAction === 'delete'){
                            this.$router.replace({
                                path: "/eventList?eventType=" + SmartStorage.get('selectedEventType'),
                            });
                        }else {
                            this.toBack();
                        }
                    }else {
                        this.$itoast.fail(res.msg);
                    }
                })
            })

        },
        // 辉瑞老yes保存会议
        pfizerSaveForTMIS(){
            console.log(this.$refs.form.getValues());
            let {
                Name,
                TypeDictVal,
                eventCity,
                QtyAttenderExt,
                QtyAttenderInt,
                Place,
                PlaceAddress,
                DtTypeTxt,
                Descr,
                DtStart,
                DtEnd,
                DtStartTime,
                DtEndTime
            } = this.$refs.form.getValues();

            this.$refs.form.validate().then(() => {
                // 前置校验
                // 1， 结束日期不能小于开始日期
                if(DtStart && DtEnd){
                    const diff = new Date(DtStart).getTime() - new Date(DtEnd).getTime();
                    if(diff > 0) {
                        this.$toast.fail('结束日期不能小于开始日期');
                        return;
                    }
                }
                // 2，结束时间大于开始时间
                if(DtStartTime && DtEndTime){
                    const diff = timeDiff(DtStartTime)(DtEndTime);
                    if(diff >= 0) {
                        this.$toast.fail('结束时间不能小于开始时间');
                        return;
                    }
                }
                // 3，内部参会人数不能为0
                if(!parseInt(QtyAttenderInt) || !parseInt(QtyAttenderExt)){
                    this.$toast.fail('内部或者外部参会人数需要大于0');
                    return;
                }
                // 4，时间加上后缀:00
                if(DtStartTime.length < 8){
                    DtStartTime = DtStartTime + ':00';
                }
                if(DtEndTime.length < 8){
                    DtEndTime = DtEndTime + ':00';
                }
                
          
                
                
                // 5, 编辑的时候卡控日期大型辉瑞主办会和第三方会三天内的话不能修改，其他的一天内不能修改
                // if(this.$route.query.type == "edit"){
                //     const startDate = new Date(DtStart + ' ' +DtStartTime).getTime();
                //     console.log(startDate);
                //     const currentDate = new Date().getTime();
                //     if(['大型辉瑞主办会', '第三方会'].includes(TypeDictVal)){
                //         if((startDate - currentDate) <= 259200000) { // 三天
                //             this.$toast.fail(`${TypeDictVal}需要提前三天申请`);
                //             return;
                //         }
                //     }else {
                //         if((startDate - currentDate) <= 86400000) { // 一天
                //             this.$toast.fail(`${TypeDictVal}需要提前一天申请`);
                //             return;
                //         }
                //     }
                // }
                const {city, country, gps, province, organization} = this.miceInfo.ExtData;
              
                // 会议日期不符合规则
                const params = {
                    "tuUserId": getCookie('userId'),
                    "province": eventCity.split('/')[0],
                    "city": eventCity.split('/')[1],
                    "typeDictTxt": TypeDictVal,
                    "place": Place,
                    "placeAddress": PlaceAddress,
                    "name": Name,
                    "dtStart": DtStart,
                    "dtEnd": DtEnd ? DtEnd : DtStart, // 如果只有开始日期这种，需要把结束日期也赋值为开始日期
                    "dtTypeTxt": DtTypeTxt || DtEnd ? DtTypeTxt : "全天",
                    "qtyAttenderInt": QtyAttenderInt,
                    "qtyAttenderExt": QtyAttenderExt,
                    "descr": Descr,
                    "miceId": this.miceInfo.MiceId,
                    "dtStartTime": DtStartTime,
                    "dtEndTime": DtEndTime,
                    "itsExtData":{
                        "dtStartTime": DtStartTime,
                        "dtEndTime": DtEndTime,
                        "city": city,
                        "country": country,
                        "gps": gps,
                        "hospital": {},
                        "hospitalId": "",
                        "isNewYes": 1,
                        "place":"",
                        "province": province,
                        "cityData": this.$smartStorage.get('cityData')
                    },
                }
                // 给特殊字段进行处理
                params.dtTypeVal = this.handleDtTypeVal(params.dtTypeTxt);
                // 给跨院云会议的特殊处理，这块逻辑很绕，不要瞎改
                if(['跨院云会议'].includes(TypeDictVal)){
                    if(Place === '餐厅') {
                        params.placeAddress =  this.$route.query.type === 'edit' ? this.miceInfo.PlaceAddress1 : '';
                    }else if(Place === '医院') {
                        params.placeAddress = this.$smartStorage.get('PlaceAddress').name;
                    }
                }else {
                    console.log(Place);
                    // 意思是，参会地址可能是输入框的情况，就直接取值PlaceAddress
                    params.placeAddress = Place ? this.$smartStorage.get('PlaceAddress') ?  this.$smartStorage.get('PlaceAddress').name : PlaceAddress : PlaceAddress;
                }
                params.itsExtData.hospital = this.$smartStorage.get('PlaceAddress');
                console.log(params);
                // 编辑时传入状态
                if(this.isEdit){
                    params.itsExtData.status = this.miceInfo.Status;
                    params.itsExtData.organization = organization;
                }
                this.$service.SaveEvent(params).then(res => {
                    if(res.success){
                        this.$smartStorage.remove('PlaceAddress');
                        this.$smartStorage.remove('cityData');
                        this.$toast('成功');
                        this.$router.go(-1);
                    }
                })
            })
        },
        handleDtTypeVal(txt){
            const mapper = new Map([
                ["上午", "1"],
                ["下午", "2"],
                ["全天", "3"]
            ])
            return mapper.get(txt)
        }
    },
    computed: {
        btnClass(){
            if(this.isPfizerEdit){
                if(this.isPfizerDraft){
                    return 'btns-3'
                }else {
                    return 'btns-1'
                }
            }else {
                return 'btns-2'
            }
        },
        pfizerBtns(){
            if(this.isPfizerEdit){
                if(this.isPfizerDraft){
                    return this.pfizerBtnsBase.filter(x => x.key !== 'create')
                }else {
                    return this.pfizerBtnsBase.filter(x => x.key === 'edit')
                }
            }else {
                return this.pfizerBtnsBase.filter(x => ['create', 'draft'].includes(x.key))
            }
        },
        // isPfizerEdit ? 'btns-3' : 'btns-2'
    }
};
</script>

<style lang="less" scoped>
.flex-display{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.create-box {
    width: 100vw;
    height: 100%;
    background-color: #ffffff;
    overflow-y: unset;

    .head-box {
        padding: 0 0.25rem 0 0.25rem;
        //margin-bottom: 0.2rem;

        .back-icon {
            text-align: left;
            padding: 0.15rem 0;
        }

        .title {
            text-align: left;
            color: #13161b;
            font-size: 0.24rem;
        }
    }

    .cnt-box {
        padding: 0 0.25rem 0.34rem 0.25rem;
        height: calc(100vh - 0.44rem);
        box-sizing: border-box;
        overflow-y: scroll;
        .btns-1{
            .van-button{
                width: 100%;
            }
        }
        .btns-2{
            .flex-display();
            .van-button{
                width: 45%;
            }
        }
        .btns-3{
            .flex-display();
            .van-button{
                width: 28%;
                &:nth-child(2){
                    width: 32%;
                }
            }
        }
        .tips-box {
            margin-bottom: 0.1rem;
        }

        .more-box {
            margin-bottom: 0.2rem;

            .more {
                display: flex;
                align-items: center;
                justify-content: center;

                .name {
                    color: #90939a;
                }

                svg {
                    display: block;
                }
            }

            .title {
                text-align: left;
                margin-bottom: 0.1rem;
            }
        }

        /deep/ .van-button__text {
            font-size: 0.16rem;
        }
    }
}
</style>
